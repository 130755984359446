<template>
  <div id="app">
    <van-nav-bar title="工单详情" left-text="返回" left-arrow @click-left="onClickLeft" />
    <van-tabs  v-model:active="active">
      <van-tab title="详情" :name="1">
        <van-pull-refresh v-model="detailLoading" @refresh="onDetailRefresh">
          <van-loading color="#1989fa" class="page-loading" :hidden="cmLoadingHidden" />
          <div class="order-detail">
            <van-form :hidden="cmLoading" ref="form">
              <van-field v-model="data.maintain_code" label="编号" readonly />
              <van-field v-model="data.eq" label="资产" readonly />
              <van-field v-model="data.workshop" label="车间" readonly />
              <van-field v-if="data.eqProcess" v-model="data.eqProcess" label="安装位置" readonly />
              <van-field v-if="data.eqPosition" v-model="data.eqPosition" label="存放位置" readonly />
              <van-field v-model="data.statusShow" label="状态" readonly />
              <van-field v-model="data.tagShow" label="维修/检测" @click="showTag = true" right-icon="arrow-down" />
              <van-field v-model="data.stopShow" label="是否停产" @click="showStop = true" right-icon="arrow-down" />
              <van-field v-model="data.moldShow" label="专业类型" @click="showMold = true" right-icon="arrow-down" />
              <van-field v-model="data.errorShow" label="故障模式" @click="showErrorPicker" right-icon="arrow-down" />
              <van-field v-model="data.create_admin_name" label="报修人" readonly />
              <van-field v-model="data.create_time" label="报修时间" readonly />
              <van-field v-model="data.fromShow" label="来源类型" readonly />
              <van-popup v-model:show="showTag" position="bottom">
                <van-picker show-toolbar :columns="applyTag" value-key="name" @confirm="hideApply" @cancel="showTag = false" />
              </van-popup>
              <van-popup v-model:show="showStop" position="bottom">
                <van-picker show-toolbar :columns="applyStop" value-key="name" @confirm="hideStop" @cancel="showStop = false" />
              </van-popup>
              <van-popup v-model:show="showMold" position="bottom">
                <van-picker show-toolbar :columns="cmMold" value-key="name" @confirm="hideMold" @cancel="showMold = false" />
              </van-popup>
              <van-popup v-model:show="showError" position="bottom">
                <van-picker show-toolbar :columns="errorCodeList" value-key="name" @confirm="hideMold" @cancel="showError = false" />
              </van-popup>
              <van-popup v-model:show="showError" position="bottom">
                <van-picker show-toolbar :columns="errorCodeList" @confirm="hideError" @cancel="showError = false" value-key="name" :loading="loadingPicker">
                  <template #title>
                    <van-search v-model="errorCodeQuery.name" @search="searchErrorCode" placeholder="请输入搜索关键词" />
                  </template>
                </van-picker>
              </van-popup>
              <van-field
                  v-if="data.status !== 0"
                  v-model="data.position_text"
                  rows="2"
                  autosize
                  label="维修部位"
                  type="textarea"
                  maxlength="200"
                  placeholder="请输入留言"
                  show-word-limit
              />
              <van-field
                  v-if="data.status !== 0"
                  v-model="data.problem_text"
                  rows="2"
                  autosize
                  label="问题描述"
                  type="textarea"
                  maxlength="200"
                  placeholder="请输入留言"
                  show-word-limit
              />
              <van-field
                  v-if="data.status !== 0"
                  v-model="data.maintain_text"
                  rows="2"
                  autosize
                  label="维护措施"
                  type="textarea"
                  maxlength="200"
                  placeholder="请输入留言"
                  show-word-limit
              />
              <div style="margin: 16px;">
                <div v-if="data.status === 0 && cmCanBegin === 2" class="buttonBox">
                  <van-button round block color="#07c160" native-type="button" @click="cmBegin">开始执行</van-button>
                </div>
                <div v-if="(data.status === 1 || data.status === -1) && cmCanEnd === 2" class="buttonBox">
                  <van-button round block color="#07c160" native-type="button" @click="cmEnd">执行完成</van-button>
                </div>
                <div v-if="data.status === 2 && cmCanMonitor === 2" class="buttonBox">
                  <van-button round block color="#07c160" native-type="button" @click="cmPass">保全确认</van-button>
                </div>
                <div v-if="data.status === 2 && data.rejected !== 2 && cmCanMonitor === 2" class="buttonBox">
                  <van-button round block type="danger" native-type="button" @click="cmReject">保全退回</van-button>
                </div>
                <div v-if="data.status === 3 && cmCanConfirm === 2" class="buttonBox" hidden>
                  <van-button round block color="#07c160" native-type="button" @click="cmWorkShopConfirm">生产确认</van-button>
                </div>
                <div v-if="1>2" class="buttonBox">
                  <van-button round block color="#1890ff" native-type="button" @click="confirmDel">删除</van-button>
                </div>
              </div>

            </van-form>
          </div>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="备件" :name="2">
        <van-pull-refresh v-model="detailLoading" @refresh="onDetailRefresh">
          <div class="">
            <van-cell-group>
              <van-cell title="备件名称" value="数量单位" label="型号" title-class="tl" />

              <van-swipe-cell v-for="(item, index) in data.parts_list" :key="index">
                <van-cell v-if="item.already === 3">
                  <div class="fl" style="color: green">{{ item.parts_name }}-{{ item.parts_spec }}</div>
                  <div class="fr" style="color: green">{{ item.number }}</div>
                </van-cell>
                <van-cell v-else-if="item.already === 2">
                  <div class="fl" style="color: blue">{{ item.parts_name }}-{{ item.parts_spec }}</div>
                  <div class="fr" style="color: blue">{{ item.number }}</div>
                </van-cell>
                <van-cell v-else>
                  <div class="fl">{{ item.parts_name }}-{{ item.parts_spec }}</div>
                  <div class="fr">{{ item.number }}</div>
                </van-cell>
                <template #right>
                  <van-button square type="danger" text="删除" @click="removePartsHandle(item.id, item.already)" />
                </template>
              </van-swipe-cell>

            </van-cell-group>
          </div>
        </van-pull-refresh>
        <div v-if="[-1, 1].includes(data.status)" class="order-detail">
          <div class="cell">
            <van-field v-model="order.partsShow" label="备件" placeholder="请选择备件" @click="showPartsPicker" right-icon="arrow-down" />
          </div>
          <van-popup v-model:show="showParts" position="bottom">
            <van-picker show-toolbar :columns="partsList" @confirm="hideParts" @cancel="showParts" value-key="name" :loading="loadingPicker">
              <template #title>
                <van-search v-model="partsQuery.name" @search="searchParts" placeholder="请输入搜索关键词" />
              </template>
            </van-picker>
          </van-popup>
          <div class="fz-16">
            <p class="pl-10">当前数量：{{ applyParts.number }}</p>
            <p class="pl-10">备件编码：{{ applyParts.coding }}</p>
            <p class="pl-10">所在库房：{{ applyParts.store }}</p>
            <p class="pl-10">备件型号：{{ applyParts.spec }}</p>
          </div>
          <van-field v-model="needPartsNumber" type="digit" label="需要数量" />
          <div class="buttonBox">
            <van-button round block color="#1890ff" @click="appendPartsHandle">添加备件</van-button>
          </div>
          <div class="buttonBox">
            <van-button round block type="success" @click="getParts">请求备件</van-button>
          </div>
        </div>
        <div class="bottomBox"></div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import API from '@/api/workorder/cm'
import SELECT from '@/api/select'
import { applyTag, applyStop, cmMold, cmStatus, cmFromType } from '@/utils/options'
import { mapGetters } from 'vuex'
import { Notify } from 'vant'
export default {
  inject: ['backUrl', 'delOne', 'selectName', 'reload'],
  data() {
    return {
      active: 1,
      cmLoadingHidden: false,
      cmLoading: true,
      detailLoading: true,
      data: {
        applyShow: null,
        lose_date: null,
        parts_list: []
      },
      complete_date: {
        minDate: new Date(2020, 0, 1),
        maxDate: new Date(2025, 10, 1),
        currentDate: new Date(),
      },
      showTag: false,
      showStop: false,
      showMold: false,
      showError: false,
      needPartsNumber: null,
      order: {},
      applyParts: {},
      loadingPicker: false,
      partsPicker: '',
      partsId: '',
      showParts: false,
      errorCodeQuery: {
        name: '',
        limit: 100
      },
      partsQuery: {
        name: '',
        limit: 100
      },
      partsList: [],
      errorCodeList: [],
      cmCanEnd: 1,
      cmStatus,
      cmFromType,
      applyTag,
      applyStop,
      cmMold
    }
  },
  computed: {
    ...mapGetters([
      'userId',
      'cmCanBegin',
      'cmCanMonitor',
      'cmCanConfirm',
      'cName',
      'jName'
    ])
  },
  created() {
    this.index()
  },
  methods: {
    index() {
      const postData = {
        id: this.$route.params.id,
        type: 2
      }
      this.cmLoadingHidden = false
      this.cmLoading = true
      API.getOne(postData).then(res => {
        this.data = res.data.info
        if (this.cmCanBegin === 2) {
          if (this.data.start_maintain_id === this.userId) {
            this.cmCanEnd = 2
          }
        }
        this.data.statusShow = this.selectName(this.cmStatus, this.data.status)
        this.data.tagShow = this.selectName(this.applyTag, this.data.tag)
        this.data.stopShow = this.selectName(this.applyStop, this.data.is_stop)
        this.data.moldShow = this.selectName(this.cmMold, this.data.mold)
        this.data.stopShow = this.selectName(this.applyStop, this.data.is_stop)
        this.data.fromShow = this.selectName(this.cmFromType, this.data.from_type)
      }).finally(() => {
        this.cmLoadingHidden = true
        this.cmLoading = false
        this.detailLoading = false
      })
    },
    hideApply(val) {
      this.showTag = false
      this.data.tag = val.value
      this.data.tagShow = val.name
    },
    hideStop(val) {
      this.showStop = false
      this.data.is_stop = val.value
      this.data.stopShow = val.name
    },
    hideMold(val) {
      this.showMold = false
      this.data.mold = val.value
      this.data.moldShow = val.name
    },
    showErrorPicker() {
      this.showError = true
      SELECT.errorCodeList(this.errorCodeQuery).then(res => {
        this.errorCodeList = res.data.data
      })
    },
    searchErrorCode() {
      SELECT.errorCodeList(this.errorCodeQuery).then(res => {
        this.errorCodeList = res.data.data
      })
    },
    hideError(val) {
      this.showError = false
      this.data.error_id = val.id
      this.data.errorShow = val.name
    },
    cmBegin() {
      const thisData = this.data
      API.cmStart(thisData).then(res => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '开始执行' })
          this.reload()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    cmEnd() {
      const postData = {
        // 维修部位 问题描述 维护措施 停线时间
        id: this.$route.params.id,
        position_text: this.data.position_text,
        problem_text: this.data.problem_text,
        maintain_text: this.data.maintain_text,
        stop_hours: this.data.stop_hours
      }
      API.cmEnd(postData).then(res => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '执行完毕！' })
          this.reload()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    cmPass() {
      const postData = {
        id: this.$route.params.id,
        type: 1
      }
      API.cmConfirm(postData).then(res => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '保全班长确认！' })
          this.backUrl()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    cmReject() {
      const postData = {
        id: this.$route.params.id,
        type: 0
      }
      API.cmConfirm(postData).then(res => {
        if (res.data.error_code === 1) {
          Notify({ type: 'danger', message: '保全班长退回！' })
          this.backUrl()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    cmWorkShopConfirm() {
      const postData = {
        id: this.$route.params.id
      }
      API.cmWorkShopConfirm(postData).then(res => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '生产班长确认' })
          this.backUrl()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    showPartsPicker() {
      this.showParts = true
      SELECT.partsList(this.partsQuery).then(res => {
        this.partsList = res.data.data
      })
    },
    searchParts() {
      this.partsQuery.add_field = 'coding'
      SELECT.partsList(this.partsQuery).then(res => {
        this.partsList = res.data.data
      })
    },
    hideParts(val) {
      this.showParts = false
      this.order.parts_id = val.id
      this.order.partsShow = val.name

      this.applyParts.number = val.number
      this.applyParts.coding = val.coding
      this.applyParts.store = val.store.name
      this.applyParts.spec = val.spec
    },
    appendPartsHandle() {
      const parts_data = {
        'parts_id': this.order.parts_id,
        'notice': this.order.notice,
        'number': this.needPartsNumber,
        'workorder_id': this.$route.params.id,
        'workorder_type': 10
      }
      API.appendParts(parts_data).then(res => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '新增成功' })
          this.index()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    removePartsHandle(id, already) {
      if (already === 3) {
        Notify({
          type: 'danger',
          message: '已经领取到手了，不能直接删除'
        })
        return false
      }
      const parts_data = {
        'id': id
      }
      API.removeParts(parts_data).then((res) => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '删除成功' })
          this.index()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    getParts() {
      const needData = {
        id: this.data.id,
        from_type: 1
      }
      API.needParts(needData).then(res => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '请求备件，请速去库房领件！' })
          this.reload()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    onDetailRefresh() {
      this.detailLoading = true
      this.index()
    },
    confirmDel() {
      const id = this.data.id
      this.delOne(API, id, true)
    },
    onConfirm(p) {
      this.partsNumber = p.number + p.invisible_number
      this.showPartsPicker = false
      this.partsPicker = p.name
      this.partsId = p.id
    },
    searchPicker(){
      this.loadingPicker = true
      // this.partsQuery
      console.log(this.partsQuery)
      SELECT.partsList(this.partsQuery).then(partsRes => {
        console.log(partsRes)
        this.partsList = partsRes.data.data
      }).finally(() => {
        this.loadingPicker = false
      })
    },
  }
}
</script>

<style scoped>
.order-detail{
  box-sizing: border-box;
  text-align: left;
  padding: 10px 10px 80px;
}
.buttonBox{
  margin-top: 10px;
}
</style>
