/**
 *  通用类
 * */
// 状态
export const commonStatus = ['启用', '停用']
export const editCommonStatus = ['启用', '停用', '删除']
/**
 *  设备
 * */
// 资产分类
export const eqAttribute = ['主要生产设备', '一般生产设备', '非生产设备']
// 资产性质
export const eqType = ['生产设备', '检测设备', '动力设施']
// 重要度
export const eqImportance = ['重点设备', '一般设备']
// 环保设备
export const eqHb = ['否', '是']
// 状态
export const eqStatus = ['在用', '停用', '闲置', '待报废', '维修中', '报废']
/**
 *  工装
 * */
export const jigUnit = ['件', '套']
export const jigType = ['Ⅰ类', 'Ⅱ类', 'Ⅲ类']

/**
 *  备品备件
 * */
// 物料类型
export const partsType = ['XB', 'ZC']
export const partsTypeDescription = ['修理用备件', '周转材料']
// 物料组
export const partsCategory = ['BJ01', 'BJ02', 'BJ03', 'GJ01', 'GJ02', 'GJ03', 'GJ04', 'GJ05', 'GJ06', 'GJ07', 'GJ08', 'GJ09', 'GJ10', 'GJ11', 'GJ12', 'GJ13']
export const partsCategoryDescription = ['机械', '电器', '热工', '标准刀具', '标准辅具', '标准夹具备件', '标准量具', '标准磨具', '标准模具备件', '非标刀具', '非标辅具', '非标量具', '非标磨具', '非标夹具备件', '非标模具备件', '吊索具']
// 估价类
export const partsEvaluation = ['Z300', 'Z200', 'Z500', 'Z501', 'Z502', 'Z503', 'Z504']
export const partsEvaluationDescription = ['原材料-修理用备件', '原材料-辅助材料-油料', '周转材料-低值易耗品-工具', '周转材料-低值易耗品-夹具', '周转材料-低值易耗品-吊具', '周转材料-低值易耗品-模具', '周转材料-低值易耗品-模具备件']
// 入库单状态
export const enterPartsStatus = [
    { value: 1, name: '创建' },
    { value: 2, name: '完毕' }
]
// 入库单类型
export const enterPartsType = [
    { value: 1, name: '采购' },
    { value: 2, name: '修旧利废' },
    { value: 3, name: '随机备件' }
]
// 出库单状态
export const appearPartsStatus = [
    { value: 1, name: '创建' },
    { value: 2, name: '完毕' }
]
// 出库单类型
export const appearPartsType = [
    { value: 1, name: '计划类' },
    { value: 2, name: '维修类' }
]
// 盘点单类型
export const partsTakeStatus = [
    { value: 1, name: '创建' },
    { value: 2, name: '完毕' }
]

/**
 *  采购管理
 * */
// 维护方式
export const planCategory = ['日常点检', '周期巡检', '完好鉴定', '加油润滑', '定期保养', '预防维修', '精度检测', '清洗换油']
// 是否停机处理
export const planStopEq = ['是', '否']
// 保全类型
export const planType = ['自主保全', '专业保全', '外委保全']
// 润滑方式
export const planLibType = ['加油', '换油']
// 周期单位
export const planPeriodUnit = ['天', '周', '月', '年']
// 是否需要审核
export const planCheck = ['否', '是']
// 报修状态
export const applyOrderStatus = [
    { value: -1, name: '审核驳回' },
    { value: 0, name: '待维修' },
    { value: 1, name: '维修中' },
    { value: 2, name: '待保全审核' },
    { value: 3, name: '待生产确认' },
    { value: 4, name: '待总结' },
    { value: 5, name: '总结完毕' }
]
// 报修类型
export const applyType = [
    { value: 1, name: '普通设备' },
    { value: 2, name: '特种设备' }
]
// 报修专业类型
export const applyMold = ['电气', '机械', '所有']
// 报修是否停产
export const applyStop = [
    { value: 1, name: '是' },
    { value: 2, name: '否' }
]
// 报修维修/检测
export const applyTag = [
    { value: 1, name: '维修' },
    { value: 2, name: '检测' }
]
/**
 *  工单
 * */
// 报修单故障级别（工程师在故障报告书中用）
export const cmRank = ['A:单台停机时间≥6小时或停线时间≥2小时', 'B:单台停机时间≥4小时或停线时间≥1小时', 'C:单台停机时间≥2小时或停线时间≥0.5小时']

export const pmStatus = [
    { value: -1, name: '待派发' },
    { value: 1, name: '待维修' },
    { value: 2, name: '完成' },
    { value: 10, name: '超时' },
    { value: 20, name: '待班长审核' },
    { value: 30, name: '待工程师审核' },
    { value: 100, name: '(超时)待维修' },
    { value: 200, name: '(超时)完成' },
    { value: 2000, name: '(超时)待班长审核' },
    { value: 3000, name: '(超时)待工程师审核' }
]

// pm1
export const pm1Status = [
    { value: 0, name: '待办' },
    { value: 1, name: '完成' },
    { value: 2, name: '超时' },
    { value: 3, name: '停产申请' },
    { value: 4, name: '停产' }
]

// pm2
export const pm2Status = [
    { value: 0, name: '待派发' },
    { value: 1, name: '待办' },
    { value: 2, name: '待班长确认' },
    { value: 3, name: '待工程师确认' },
    { value: 4, name: '执行完毕' },
    { value: 4, name: '超时' }
]
// cm
export const cmStatus = [
    { value: -1, name: '审核驳回' },
    { value: 0, name: '待维修' },
    { value: 1, name: '维修中' },
    { value: 2, name: '待保全审核' },
    { value: 3, name: '待生产确认' },
    { value: 4, name: '维修完成' }
]
export const cmMold = [
    { value: 1, name: '电气' },
    { value: 2, name: '机械' },
    { value: 3, name: '所有' }
]
export const cmFromType = [
    { value: 1, name: '报修' },
    { value: 2, name: '计划' }
]
/**
 *  部门管理
 * */

/**
 *  系统管理
 * */

/**
 *  文档管理
 */

/**
 *  消息管理
 */
export const messageStatus = ['未读', '已读']
