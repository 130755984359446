import request from "@/utils/request"

export default {
    partsList(params) {
        return request({
            url: '/v1/partsList',
            method: 'get',
            params
        })
    },
    errorCodeList(params) {
        return request({
            url: '/v1/errorCodeList',
            method: 'get',
            params
        })
    },
    adminList(params) {
        return request({
            url: '/v1/adminList',
            method: 'get',
            params
        })
    },
    eqList(params) {
        return request({
            url: '/v1/eqList',
            method: 'get',
            params
        })
    },
    eqQrcode(params) {
        return request({
            url: '/v1/eqQrcode',
            method: 'get',
            params
        })
    },
    storeList(params) {
        return request({
            url: '/v1/storeList',
            method: 'get',
            params
        })
    },
    qrcodePm(params) {
        return request({
            url: '/v1/qrcodePm',
            method: 'get',
            params
        })
    }
}
