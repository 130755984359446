import request from '@/utils/request'

export default {
    cmList(params) {
        return request({
            url: '/v1/cmList',
            method: 'get',
            params
        })
    },
    getOne(params) {
        return request({
            url: '/v1/cm',
            method: 'get',
            params
        })
    },
    update(data) {
        return request({
            url: '/v1/cmUpdate',
            method: 'post',
            data
        })
    },
    delete(id) {
        return request({
            url: `/v1/maintainDel/${id}`,
            method: 'get'
        })
    },
    cmStart(data) {
        return request({
            url: '/v1/cmStart',
            method: 'post',
            data
        })
    },
    cmEnd(data) {
        return request({
            url: '/v1/cmEnd',
            method: 'post',
            data
        })
    },
    cmConfirm(data) {
        return request({
            url: '/v1/cmConfirm',
            method: 'post',
            data
        })
    },
    cmWorkShopConfirm(data) {
        return request({
            url: '/v1/cmWorkshopConfirm',
            method: 'post',
            data
        })
    },
    appendParts(data) {
        return request({
            url: '/v1/woAppendParts',
            method: 'post',
            data
        })
    },
    removeParts(data) {
        return request({
            url: '/v1/woRemoveParts',
            method: 'post',
            data
        })
    },
    editParts(data) {
        return request({
            url: '/v1/woEditParts',
            method: 'post',
            data
        })
    },
    needParts(data) {
        return request({
            url: '/v1/woNeedParts',
            method: 'post',
            data
        })
    },
    out(id) {
        return request({
            url: `/v1/cmToOut/${id}`,
            method: 'get'
        })
    }
}
